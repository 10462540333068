@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.component.table-layout {
  flex: 1 1;
}
.component.table-layout header {
  display: flex;
  align-items: center;
}
.component.table-layout header > * {
  flex: 1 1;
}
.component.table-layout header .ant-input-search {
  flex: initial;
}
.component.table-layout header .buttons {
  display: flex;
  justify-content: flex-end;
}
.component.table-layout header .ant-btn-group {
  margin-left: 1em;
}
.component.table-layout footer {
  display: flex;
  margin-top: 1em;
}
.component.table-layout footer > * {
  flex: 1 1;
}
.component.table-layout footer .ant-btn-group {
  margin-left: 1em;
}
.component.table-layout main .table-layout-table:not(:last-of-type) {
  margin-bottom: 1em;
}
.component.table-layout main,
.component.table-layout main .ant-table-wrapper,
.component.table-layout main .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
}
.component.table-layout main ul.ant-table-pagination.ant-pagination {
  position: relative;
  margin: 1em 0;
  z-index: 1;
}
.component.table-layout main ul.ant-table-pagination.ant-pagination:last-child {
  margin-bottom: 0;
}
.component.table-layout main .ant-table {
  display: inline;
}
.component.table-layout main .ant-table-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 0;
  padding: 0;
  border: none;
}
.component.table-layout main .ant-table-title:empty {
  margin-bottom: 0;
}
.component.table-layout main .ant-table-title p {
  display: flex;
  margin: 0;
  margin-top: 1em;
  height: 32px;
  align-items: center;
}
.component.table-layout main .ant-table-title h2 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}
.component.table-layout main .ant-table-empty .ant-table-body {
  border-bottom: none !important;
}
.component.table-layout main .ant-table-placeholder {
  border-bottom: none;
}
.component.table-layout main .ant-table-content {
  border: 1px solid #e8e8e8;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table {
  border-radius: 0;
  display: block;
  border-top: none;
  border-right: none;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table-body {
  margin: 0;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table-content {
  border: none;
}
.component.table-layout main .ant-table-content .ant-table-body:not(:only-child) {
  border-bottom: 1px solid #e8e8e8;
}
.component.table-layout main .ant-table-content .ant-table-body td span.anchor.delete {
  color: #f5222d;
}
.component.table-layout main .ant-table-content .ant-table-body td span.anchor.delete span::before {
  content: ' ';
}
.component.table-layout main .ant-table-tbody > tr:last-of-type > td {
  border: none;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal container styles */
.modal p {
  margin: 0;
}
.modal .ant-modal-body .ant-alert {
  margin-bottom: 1em;
}
.modal .ant-modal-body .ant-form-item:last-of-type {
  margin-bottom: 0;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.flag-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  margin: 0;
  padding: 0;
}
.flag-select img {
  width: 1.3em;
  height: 1.3em;
}
.flag-select .country-flag {
  cursor: pointer;
}
.flag-select .country-flag .country-label {
  font-size: 1rem;
  line-height: 2rem;
  position: relative;
  vertical-align: middle;
}
.flag-select .selected--flag--option {
  cursor: pointer;
  padding: 0 8px;
}
.flag-select .selected--flag--option::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}
.flag-select .selected--flag--option:focus {
  outline: none;
}
.flag-select .selected--flag--option .country-label {
  margin-left: 9px;
}
.flag-select .selected--flag--option .country-flag {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flag-select .flag-options {
  position: absolute;
  z-index: 999999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #fff;
  margin-top: 0.25rem;
  overflow: auto;
  top: 64px;
  right: 0;
}
.flag-select .flag-options.to--left {
  right: 10px;
}
.flag-select .flag-option {
  display: block;
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  cursor: pointer;
}
.flag-select .flag-option:hover {
  background-color: #fff4e6;
}
.flag-select .flag-option:focus {
  outline: none;
}
.flag-select .flag-option .country-label {
  padding: 0 9px;
}
.flag-select .filterBox {
  width: 100%;
}
.flag-select .filterBox input {
  width: 90%;
  margin: 0 4%;
}
.flag-select .filterBox input:focus {
  outline: none;
}
.flag-select .arrow-down {
  display: none;
  color: rgba(0, 0, 0, 0.65);
  padding: 2px;
}
.flag-select .hidden {
  display: none;
}
.flag-select .no--focus {
  pointer-events: none;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* StrengthIndicator styles */
.strength-indicator {
  width: 250px;
}
.strength-indicator-status-weak {
  color: #f5222d;
}
.strength-indicator-status-fair {
  color: #faad14;
}
.strength-indicator-status-good {
  color: #5db12f;
}
.strength-indicator-status-strong {
  color: #5db12f;
}
.strength-indicator-progress-weak .ant-progress-bg {
  background-color: #f5222d;
}
.strength-indicator-progress-fair .ant-progress-bg {
  background-color: #faad14;
}
.strength-indicator-progress-good .ant-progress-bg {
  background-color: #5db12f;
}
.strength-indicator-progress-strong .ant-progress-bg {
  background-color: #5db12f;
}
.strength-indicator-suggestions {
  margin: 0;
  padding: 0;
  margin-top: 1em;
}
.strength-indicator-suggestions-item {
  margin: 0;
  padding: 0;
  margin-left: 16px;
}
.strength-indicator-warning {
  margin: 0;
  padding: 0;
  margin-top: 1em;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* == Screen styles */
.screen.profile header {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}
.screen.profile header .resume {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: row;
}
.screen.profile header .resume .profile-lastName::before {
  content: ' ';
}
.screen.profile header .resume .profile-job::before {
  content: ' \2022   ';
}
.screen.profile header .resume .avatar-uploader {
  margin-right: 1em;
}
.screen.profile header .resume .informations {
  overflow: hidden;
}
.screen.profile header .resume .informations .ant-alert-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen.profile header .resume .informations h2.contact {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.25em;
  margin-bottom: 1rem;
}
.screen.profile header .resume .informations h2.contact .contact {
  text-transform: uppercase;
  margin-right: 0.5ch;
}
.screen.profile .ant-form-item button {
  align-self: flex-start;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.restaurantOwners .user-avatar {
  margin-right: 0.5em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.modal-imagesCarousel .carousel {
  max-height: 65vh;
  height: 48em;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}
.modal-imagesCarousel .carousel-image-item {
  position: relative;
}
.modal-imagesCarousel .carousel-image-nav-prev {
  position: absolute;
  display: flex;
  top: 0;
  left: -10;
  bottom: 0;
  align-items: center;
  cursor: pointer;
}
.modal-imagesCarousel .carousel-image-nav-next {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  cursor: pointer;
}
.modal-imagesCarousel .icon {
  color: #FF6200;
}
.ant-modal-body {
  align-items: center;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 100%;
  overflow: hidden;
}
.ant-carousel .slick-slide h3 {
  color: #ff6200;
  margin-bottom: 2em;
  margin-top: 1em !important;
}
.ant-carousel .slick-dots li button {
  background: #ff6200 !important;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.restaurantOwner-profile .ant-avatar {
  font-size: 5em;
  height: 1em;
  width: 1em;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  margin-right: 1rem;
}
.restaurantOwner-profile-details {
  display: flex;
  margin-bottom: 2em;
}
.restaurantOwner-profile-details-permissions-title {
  font-size: 1.2rem;
}
.restaurantOwner-profile-details-permissions-list {
  margin-bottom: 1rem;
}
.restaurantOwner-profile-details-permissions-list-icon {
  font-size: 1.2rem;
  padding-right: 1rem;
}
.restaurantOwner-profile-details-permissions-list-icon-enable {
  color: #5db12f;
}
.restaurantOwner-profile-details-permissions-list-icon-disable {
  color: #f5222d;
}
.restaurantOwner-profile-details-permissions-list .ant-list-item {
  display: inline-flex;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8 !important;
}
.restaurantOwner-profile-details-permissions-list-text {
  flex-grow: 1;
}
.restaurantOwner-profile-details-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}
.restaurantOwner-profile-details-information {
  display: flex;
  flex-direction: row;
}
.restaurantOwner-profile-details-information > :not(:last-child) {
  margin-bottom: 0.5em;
}
.restaurantOwner-profile-details-information-btn {
  margin-left: 2em;
  align-self: center;
}
.restaurantOwner-profile-details-information-openCarousel {
  margin-bottom: 2em;
  align-self: center;
}
.restaurantOwner-profile-details-information-dates {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 1em;
}
.restaurantOwner-profile-details h2 {
  display: flex;
  align-items: center;
}
.restaurantOwner-profile-header {
  display: flex;
  align-items: center;
}
.restaurantOwner-profile p {
  margin: 0;
}
.restaurantOwner-profile-description-group:not(:first-of-type) {
  margin-top: 1em;
}
.restaurantOwner-profile-description-group h3 {
  margin-bottom: 2rem;
  font-weight: initial;
}
.restaurantOwner-profile-description-group h4 {
  color: #FF6200;
  font-size: 1em;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}
.restaurantOwner-profile-description-group section {
  margin-left: 1em;
}
.restaurantOwner-profile-description-item {
  display: flex;
  flex-direction: row;
  padding: 1em 0;
}
.restaurantOwner-profile-description-item:not(:last-of-type) {
  border-bottom: 1px solid #e8e8e8;
}
.restaurantOwner-profile-description-item-itemMenu {
  flex-direction: column;
}
.restaurantOwner-profile-description-item-value {
  margin: 0;
  width: 60%;
  margin-left: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.restaurantOwner-profile-description-item-value .hours-item-start::after {
  content: ' - ';
}
.restaurantOwner-profile-description-item-value.link {
  flex-direction: row;
  align-items: center;
}
.restaurantOwner-profile-description-item-value.link button {
  margin-left: 8px;
}
.restaurantOwner-profile-description-item-menuBoard {
  align-self: center;
  max-height: 69vh;
}
.restaurantOwner-profile-description-item-name {
  width: 40%;
  margin-right: 1em;
  color: rgba(0, 0, 0, 0.85);
}
.restaurantOwner-profile-description-item-name::after {
  content: ' : ';
}
.restaurantOwner-profile-description-item-name-menuTitle {
  color: #FF6200;
  size: 3em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.restaurantOwners .user-avatar {
  margin-right: 0.5em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.statistics {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
}
.statistics-view.MuiCardContent-root:last-child {
  position: relative;
  background-color: #fff;
  padding-bottom: 72px;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
@media print {
  .statistics-header {
    display: none;
  }
}
.statistics-header-label {
  padding-right: 24px;
}
.statistics-header-select {
  min-width: 320px;
}
.statistics-header-action {
  padding: 0 32px;
}
.statistics-header .button-error {
  color: #f5222d;
  border-color: #f5222d;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.login {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screen.login .ant-card {
  width: 500px;
}
.screen.login .ant-card-head {
  text-align: center;
}
.screen.login .ant-card-head-title {
  overflow: visible;
}
.screen.login .ant-card-head-title .logo {
  height: 8em;
  margin-top: -5em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.loading {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.f36968cd.eot);
  src: url(/static/media/icomoon.f36968cd.eot#iefix) format('embedded-opentype'), url(/static/media/icomoon.8afd69ea.ttf) format('truetype'), url(/static/media/icomoon.806e97cf.woff) format('woff'), url(/static/media/icomoon.0db4bb77.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-img-check .path1::before {
  content: '\E952';
  color: #386cff;
}
.icon-img-check .path2::before {
  content: '\E953';
  margin-left: -1em;
  color: #ffffff;
}
.icon-picto-armchair::before {
  content: '\E954';
  color: #dcbe61;
}
.icon-picto-blinds::before {
  content: '\E965';
}
.icon-picto-camera::before {
  content: '\E966';
}
.icon-picto-night-mode::before {
  content: '\E967';
  color: #386cff;
}
.icon-picto-out::before {
  content: '\E968';
  color: #4ecd83;
}
.icon-picto-socket::before {
  content: '\E969';
}
.icon-picto-thermostat::before {
  content: '\E96A';
}
.icon-picto-user-white::before {
  content: '\E96B';
  color: #fff;
}
.icon-favicon_huandco .path1::before {
  content: '\E96C';
  color: #ffffff;
}
.icon-favicon_huandco .path2::before {
  content: '\E96D';
  margin-left: -1em;
  color: #ffffff;
}
.icon-favicon_huandco .path3::before {
  content: '\E96E';
  margin-left: -1em;
  color: #ff643b;
}
.icon-favicon_huandco .path4::before {
  content: '\E96F';
  margin-left: -1em;
  color: #386cff;
}
.icon-picto-network-0::before {
  content: '\E900';
  color: #979797;
}
.icon-picto-network-1::before {
  content: '\E94F';
  color: #979797;
}
.icon-picto-network-2::before {
  content: '\E950';
  color: #979797;
}
.icon-picto-network-3::before {
  content: '\E951';
  color: #979797;
}
.icon-picto-dashboard::before {
  content: '\E957';
}
.icon-picto-devices::before {
  content: '\E958';
}
.icon-picto-group-setup::before {
  content: '\E959';
}
.icon-picto-keyboard::before {
  content: '\E95A';
}
.icon-picto-timeline::before {
  content: '\E95B';
}
.icon-picto-user::before {
  content: '\E95C';
}
.icon-picto-xmpp::before {
  content: '\E95D';
}
.icon-picto-dissociate::before {
  content: '\E94E';
}
.icon-picto-download::before {
  content: '\E94C';
}
.icon-picto-eye::before {
  content: '\E94D';
}
.icon-picto-edit-grey::before {
  content: '\E94B';
}
.icon-picto-calendar::before {
  content: '\E947';
  color: #fff;
}
.icon-picto-import::before {
  content: '\E948';
  color: #fff;
}
.icon-picto-print::before {
  content: '\E949';
  color: #fff;
}
.icon-picto-trash::before {
  content: '\E94A';
}
.icon-picto-home::before {
  content: '\E922';
}
.icon-logo-huandco-login .path1::before {
  content: '\E901';
  color: #ff643b;
}
.icon-logo-huandco-login .path2::before {
  content: '\E902';
  margin-left: -2.65527344em;
  color: #386cff;
}
.icon-logo-huandco-login .path3::before {
  content: '\E903';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path4::before {
  content: '\E904';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path5::before {
  content: '\E905';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path6::before {
  content: '\E906';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path7::before {
  content: '\E907';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path8::before {
  content: '\E908';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path9::before {
  content: '\E909';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path10::before {
  content: '\E90A';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path11::before {
  content: '\E90B';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path12::before {
  content: '\E90C';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path13::before {
  content: '\E90D';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path14::before {
  content: '\E90E';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path15::before {
  content: '\E90F';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path16::before {
  content: '\E910';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path17::before {
  content: '\E911';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path18::before {
  content: '\E912';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path19::before {
  content: '\E913';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path20::before {
  content: '\E914';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path21::before {
  content: '\E915';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path22::before {
  content: '\E916';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path23::before {
  content: '\E95E';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path1::before {
  content: '\E917';
  color: #ff643b;
}
.icon-logo-huandco-menu .path2::before {
  content: '\E918';
  margin-left: -2.640625em;
  color: #386cff;
}
.icon-logo-huandco-menu .path3::before {
  content: '\E919';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path4::before {
  content: '\E91A';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path5::before {
  content: '\E91B';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path6::before {
  content: '\E91C';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path7::before {
  content: '\E91D';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path8::before {
  content: '\E91E';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path9::before {
  content: '\E91F';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path10::before {
  content: '\E920';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path11::before {
  content: '\E921';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path12::before {
  content: '\E923';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path13::before {
  content: '\E924';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path14::before {
  content: '\E925';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path15::before {
  content: '\E926';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path16::before {
  content: '\E927';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path17::before {
  content: '\E928';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path18::before {
  content: '\E929';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path19::before {
  content: '\E92A';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path20::before {
  content: '\E92B';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path21::before {
  content: '\E92C';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path22::before {
  content: '\E92D';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path23::before {
  content: '\E92E';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-picto-add::before {
  content: '\E92F';
  color: #30a4f5;
}
.icon-picto-arrow-left::before {
  content: '\E930';
  color: #30a4f5;
}
.icon-picto-arrow-right::before {
  content: '\E931';
  color: #30a4f5;
}
.icon-picto-arrow-up::before {
  content: '\E932';
}
.icon-picto-backend::before {
  content: '\E933';
}
.icon-picto-clock::before {
  content: '\E934';
  color: #fff;
}
.icon-picto-close::before {
  content: '\E935';
}
.icon-picto-cursor::before {
  content: '\E936';
}
.icon-picto-date::before {
  content: '\E937';
  color: #fff;
}
.icon-picto-design::before {
  content: '\E938';
}
.icon-picto-edit::before {
  content: '\E939';
  color: #fff;
}
.icon-picto-gateway::before {
  content: '\E93A';
}
.icon-picto-id::before {
  content: '\E93B';
  color: #fff;
}
.icon-picto-installers::before {
  content: '\E93C';
}
.icon-picto-notifications::before {
  content: '\E93D';
}
.icon-picto-password::before {
  content: '\E93E';
  color: #fff;
}
.icon-picto-radio-active::before {
  content: '\E93F';
  color: #30a4f5;
}
.icon-picto-radio-inactive::before {
  content: '\E940';
}
.icon-picto-role::before {
  content: '\E941';
}
.icon-picto-search-grey-small::before {
  content: '\E942';
}
.icon-picto-search::before {
  content: '\E943';
}
.icon-picto-settings::before {
  content: '\E944';
}
.icon-picto-transmitters::before {
  content: '\E945';
}
.icon-picto-update-menu::before {
  content: '\E946';
}

/* Fonts                          */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Mediaqueries */
/* Styles d'impression */
@media print {
  .ant-layout-sider,
  .ant-layout-header {
    display: none !important;
  }
}
/* Variables */
@media screen and (max-width: 576px) {
  .ant-exception-exception {
    text-align: center;
  }
}
/* Libraries */
/* Styles additionnels */
/* Flexbox classes (from Knacss) */
[class*='flex-container'] {
  display: flex;
}
.flex-container-h {
  flex-direction: row;
}
.flex-container-v {
  flex-direction: column;
}
.flex-item-fluid {
  flex: 1 1;
}
.flex-align-center-h {
  justify-content: center;
}
.flex-align-center-v {
  align-items: center;
}
/* Classes styles */
span.valid,
.anticon.valid {
  color: #5db12f;
}
span.important,
.anticon.important {
  color: #ff771d;
}
span.critical,
.anticon.critical {
  color: #f5222d;
}
/* Layout styles */
html,
body {
  overflow: hidden;
  font-size: 14px;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}
.hidden {
  display: none !important;
}
main.screen,
main.tab {
  padding: 20px;
  width: 100%;
  margin-bottom: auto;
}
main.screen::after,
main.tab::after {
  content: '';
  display: block;
  height: 20px;
}
span.anchor {
  color: #FF6200;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
span.anchor:active,
span.anchor:hover {
  outline: 0;
  text-decoration: none;
}
span.anchor:active {
  color: #d94c00;
}
span.anchor:hover {
  color: #ff8229;
}
span.anchor:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}
.avatar-uploader .ant-upload {
  height: 2.5em;
  width: 2.5em;
  border-radius: 100%;
}
.avatar-uploader .ant-upload .avatar {
  font-size: 2.5em;
  height: 2.5em;
  width: 2.5em;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}
.buttons {
  text-align: right;
  margin: -0.5ch -1ch;
  margin-left: calc(1em - 1ch);
}
.buttons > * {
  margin: 0.5ch 1ch;
}
.buttons p {
  margin: 0;
  margin-top: 0.25rem;
}
/* Ant Design styles */
.ant-select-icon {
  left: 12px;
  z-index: 1;
  display: flex;
  line-height: 1.5;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.ant-select-icon svg {
  overflow: visible;
}
.ant-select-icon + .ant-select .ant-select-selection__rendered {
  margin-left: 30px;
}
.ant-select-icon + .ant-select .ant-select-selection__placeholder {
  margin-left: 0;
}
.ant-table-search {
  padding: 1em;
  display: flex;
}
.ant-table-search input:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-table-search button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-table-body {
  overflow: auto;
}
.ant-table-body table {
  background-color: #fff;
}
.ant-table-body table thead > tr > th {
  background-clip: padding-box !important;
}
.ant-form-explain {
  margin-top: 0.25em;
}
.ant-form-item {
  margin: 0;
  position: relative;
}
.ant-form-item-children {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-form-item:not(:first-of-type) {
  margin-top: 1.25em;
}
.ant-form-item-control {
  line-height: 1em !important;
}
.ant-form-item-control .ant-table-content {
  border: 1px solid #e8e8e8;
}
.ant-form-item-control .ant-table-tbody > tr:last-of-type > td {
  border: none;
}
.ant-form-item-control .ant-table-title {
  display: flex;
  padding: 0;
  border: none;
  justify-content: flex-end;
  margin-top: -32px;
  margin-bottom: 1em;
}
.ant-form-item-control:not(.has-error) .ant-form-explain-holder {
  display: none;
}
.ant-form-item-control .password-rules {
  flex: 1 1;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0.5em;
  align-self: flex-start;
}
.ant-form-item-label {
  line-height: initial !important;
  margin-bottom: 0.5em !important;
}
.ant-form-item-label label::after {
  content: '';
}
.ant-avatar > img {
  object-fit: cover;
}
.ant-card-actions {
  display: flex;
}
.ant-card-actions > li {
  padding: 0 2em;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  float: none;
  flex: 1 1;
  width: auto !important;
}
.ant-card-actions button {
  position: relative;
  z-index: 1;
}
.ant-calendar-picker {
  width: 100%;
}
.ant-calendar-input {
  text-align: center;
}
.ant-calendar-date-input-wrap {
  margin: 0;
}
.ant-calendar-range .ant-calendar-date-panel {
  display: flex;
}
.ant-calendar-range-middle {
  margin: 0;
  left: auto;
  width: 100%;
}
.ant-calendar-range-part {
  flex: 1 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.ant-progress-line,
.ant-progress-line > * {
  display: flex;
  width: 100%;
}
.ant-progress-outer {
  display: flex;
  align-items: center;
  padding-right: 0;
  margin-right: 0;
  flex: 1 1;
}
.ant-progress-text {
  display: flex;
  width: auto;
}
.ant-tabs {
  display: inline;
}
.ant-tabs-card .ant-tabs-bar {
  margin-bottom: 0;
}
.ant-tabs-card .ant-card.tab {
  border-top: none;
}
.ant-tabs-search.ant-input-search {
  position: relative;
  display: inline-flex;
  max-width: 500px;
  float: right;
  height: 40px;
  z-index: 1;
}
.ant-tabs-search.ant-input-search input.ant-input {
  border-bottom-left-radius: 0;
  height: 40px;
  border-color: #e8e8e8;
}
.ant-tabs-search.ant-input-search .ant-input-search-button {
  border-bottom-right-radius: 0;
  height: 40px;
}
.ant-tabs.screen {
  display: flex;
  flex-direction: column;
}
.ant-tabs.screen .ant-tabs-bar {
  background-color: #fff;
  margin: 0;
}
.ant-tabs.screen .ant-tabs-content {
  margin-left: 0 !important;
  overflow-y: auto;
}
.ant-tabs.screen .ant-tabs-content .ant-tabs-tabpane-inactive {
  display: none;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
  margin-top: 1.25em;
}
.ant-dropdown-menu-item {
  padding: 0;
  display: flex;
}
.ant-dropdown-menu-item a,
.ant-dropdown-menu-item span.anchor {
  color: rgba(0, 0, 0, 0.65);
  padding: 5px 12px;
  margin: 0;
}
.ant-dropdown-menu-item a i.anticon,
.ant-dropdown-menu-item span.anchor i.anticon {
  margin-right: 1ch;
}
.ant-exception-exception {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: calc(100% - 20px);
  min-height: 500px;
}
.ant-exception-exception .ant-exception-imgBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 62.5%;
  zoom: 1;
}
.ant-exception-exception .ant-exception-imgBlock::after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-exception-exception .ant-exception-imgBlock::before,
.ant-exception-exception .ant-exception-imgBlock::after {
  content: ' ';
  display: table;
}
.ant-exception-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.ant-exception-content {
  flex: auto;
}
.ant-exception-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.ant-exception-content .ant-exception-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.25rem;
  line-height: 28px;
  margin-bottom: 1rem;
}
.ant-exception-content .ant-exception-actions button:not(:last-child) {
  margin-right: 8px;
}
/**
 * LAYOUT FULL SCREEN
 *
 * sticky header
 * scrollable content
 * sticky footer
 */
#root .ant-layout {
  height: 100vh;
}
#root .ant-layout-header {
  height: 64px;
  display: flex;
  background-color: #fff;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
#root .ant-layout-header h1 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.5em;
}
#root .ant-layout-header h1 .breadcrumbs-crumb::after {
  content: ' / ';
}
#root .ant-layout-header nav ul {
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
}
#root .ant-layout-header nav ul li {
  height: 64px;
  display: flex;
  cursor: pointer;
}
#root .ant-layout-header nav ul li:not(:last-of-type) {
  margin-right: 1em;
}
#root .ant-layout-header .ant-dropdown-link.ant-dropdown-trigger .avatar {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  object-fit: cover;
  margin-right: 1ch;
  height: 1.5em;
  width: 1.5em;
  font-size: 1rem;
}
#root .ant-layout-content {
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 64px);
}
#root .ant-layout-sider {
  color: #fff;
  background-color: #FF6200;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.1);
}
#root .ant-layout-sider-trigger {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
#root .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
#root .ant-layout-sider-children .ant-layout-sider-logo {
  background-color: #d94c00;
  height: 64px;
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-layout-sider-logo-image {
  padding: 1em;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#root .ant-layout-sider-children .ant-menu {
  flex: 1 1;
  color: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-menu-submenu {
  color: inherit;
}
#root .ant-layout-sider-children .ant-menu-submenu-arrow::before {
  background-image: none;
}
#root .ant-layout-sider-children .ant-menu-submenu-arrow::after {
  background-image: none;
}
#root .ant-layout-sider-children .ant-menu-submenu-title {
  color: inherit;
}
#root .ant-layout-sider-children .ant-menu-submenu-title:hover {
  background: rgba(255, 160, 82, 0.4);
}
#root .ant-layout-sider-children .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: #fff;
}
#root .ant-layout-sider-children .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #fff;
}
#root .ant-layout-sider-children .ant-menu-submenu-selected {
  background-color: rgba(179, 57, 0, 0.4);
}
#root .ant-layout-sider-children .ant-menu-submenu:active {
  background-color: rgba(179, 57, 0, 0.3);
}
#root .ant-layout-sider-children .ant-menu-submenu::after {
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-menu-submenu a {
  color: inherit;
}
#root .ant-layout-sider-children .ant-menu-item {
  color: inherit;
}
#root .ant-layout-sider-children .ant-menu-item:hover {
  background: rgba(255, 160, 82, 0.4);
}
#root .ant-layout-sider-children .ant-menu-item-selected {
  background-color: rgba(179, 57, 0, 0.4);
}
#root .ant-layout-sider-children .ant-menu-item:active {
  background-color: rgba(179, 57, 0, 0.3);
}
#root .ant-layout-sider-children .ant-menu-item::after {
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-menu-item a {
  color: inherit;
}
#root .ant-layout-footer {
  padding: 0 20px;
  margin-top: calc(2 * 20px);
  color: rgba(0, 0, 0, 0.45);
}
#root .ant-layout-footer::after {
  content: '';
  display: block;
  height: 20px;
}
#root .promotion-code-action {
  margin-left: -8px;
  margin-right: -8px;
}
#root .promotion-code-action button {
  margin-left: 8px;
  margin-right: 8px;
}

